@import "src/app/styles/buttons";

.input-primary-container {
  position: relative;
  min-height: 48px;
  overflow: hidden;

  &:focus-within {
    .warning {
      display: none;
    }

    .error {
      display: none;
    }
  }

  .input-primary {
    color: $neutral-30;
    background-color: $interaction-transparent-4;
    width: 100%;
    height: 48px;
    font-family: "Noto Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 17.5px;
    border-bottom: 1px solid $neutral-90;
    border-radius: 4px 4px 0 0;
    transition: all 0.15s ease-out;

    &:last-child {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    &.ng-invalid.ng-touched:not(:focus), &.ng-invalid.ng-dirty:not(:focus) {
      color: $error;
      background-color: $error-transparent-8;
      border-bottom: 1px solid $error;
    }

    &:disabled {
      background-color: $surface-70;
      border-bottom: 1px solid $neutral-90;
    }

    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
      top: 6px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      user-select: none;
    }

    &:focus {
      outline: none;
      background-color: $interaction-transparent-8;
      border-bottom: 1px solid $interaction-dark;
    }
  }

  select.input-primary {
    padding-right: 32px;
    text-overflow: ellipsis;
    appearance: none;
    background-size: 16px 16px;
    background-position: right 8px center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M11.7891%2015.9044L20.1362%207.55803C20.3803%207.31397%2020.776%207.31399%2021.0201%207.55807C21.2642%207.80216%2021.2641%208.19789%2021.02%208.44196L12.6454%2016.8158C12.6453%2016.8159%2012.6453%2016.816%2012.6452%2016.816C12.6451%2016.8161%2012.6451%2016.8161%2012.645%2016.8162C12.5327%2016.9286%2012.3994%2017.0178%2012.2526%2017.0786C12.1056%2017.1395%2011.9481%2017.1709%2011.7891%2017.1709C11.63%2017.1709%2011.4725%2017.1395%2011.3256%2017.0786C11.1788%2017.0178%2011.0454%2016.9286%2010.9331%2016.8162C10.933%2016.8161%2010.9329%2016.816%2010.9327%2016.8158L2.55808%208.44196C2.31399%208.19789%202.31397%207.80216%202.55804%207.55807C2.8021%207.31399%203.19783%207.31397%203.44192%207.55803L11.7891%2015.9044ZM11.7609%2015.9326C11.761%2015.9325%2011.7611%2015.9324%2011.7612%2015.9323L11.7609%2015.9326Z%22%20fill%3D%22currentColor%22%2F%3E%3C%2Fsvg%3E%0A");
  }

  label {
    cursor: text;
    position: absolute;
    top: 12px;
    left: 8px;
    text-align: left;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $neutral-50;
    width: 100%;
    padding-right: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.15s ease-out;
    user-select: none;
    pointer-events: none;
  }
}

input[type="radio"].input-primary-radio {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-items: center;
  margin: 0;
  background-color: $surface-90;
  border-style: solid;
  border-width: 1px;
  border-color: $neutral-50;
  box-shadow: inset 0 0 0 3px $surface-90;
  border-radius: 24px;
  transition: all 0.15s ease-out;

  &:disabled {
    background-color: $surface-90;
    border-color: $neutral-90;

    & + label {
      color: $neutral-90;
    }
  }

  &.ng-invalid.ng-touched, &.ng-invalid.ng-dirty {
    background-color: $surface-90;
    border-color: $error;

    & + label {
      color: $error;
    }
  }

  &:checked {
    background-color: $interaction;
    border-color: $interaction;

    &:disabled {
      background-color: $neutral-90;
      border-color: $neutral-90;

      & + label {
        color: $neutral-90;
      }
    }

    &.ng-invalid.ng-touched, &.ng-invalid.ng-dirty {
      background-color: $error;
      border-color: $error;

      & + label {
        color: $error;
      }
    }
  }
}

input[type="checkbox"].input-primary-checkbox {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  display: inline-flex;
  align-items: center;
  justify-items: center;
  margin: 0;
  background-color: $surface-90;
  border-style: solid;
  border-width: 1px;
  border-color: $interaction;
  border-radius: 2px;
  transition: background-color 0.15s ease-out;

  &:disabled {
    background-color: $surface-90;
    border-color: $neutral-90;

    & + label {
      color: $neutral-90;
    }
  }

  &.ng-invalid.ng-touched, &.ng-invalid.ng-dirty {
    background-color: $surface-90;
    border-color: $error;

    & + label {
      color: $error;
    }
  }

  &:checked {
    background-color: $interaction;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%0A%20%20%20%20%20%20%20%20d%3D%22M20.9366%202.48804C21.2194%202.68599%2021.2881%203.0757%2021.0902%203.35848L9.30054%2020.2C9.3004%2020.2002%209.30067%2020.1998%209.30054%2020.2C9.08845%2020.5046%208.80666%2020.755%208.47913%2020.9296C8.1514%2021.1042%207.78685%2021.1985%207.41552%2021.2047C7.0442%2021.2108%206.67673%2021.1286%206.3434%2020.9649C6.01033%2020.8013%205.72088%2020.5609%205.49885%2020.2636C5.49868%2020.2633%205.49902%2020.2638%205.49885%2020.2636L2.50006%2016.2657C2.29294%2015.9896%202.34888%2015.5978%202.62501%2015.3907C2.90113%2015.1836%203.29289%2015.2395%203.50001%2015.5156L6.50018%2019.5153C6.60382%2019.6542%206.73899%2019.7665%206.89454%2019.8429C7.05009%2019.9194%207.22158%2019.9577%207.39486%2019.9548C7.56815%2019.952%207.73827%2019.908%207.89121%2019.8265C8.04416%2019.745%208.17554%2019.6283%208.27454%2019.486L8.27552%2019.4846L20.0661%202.64163C20.2641%202.35885%2020.6538%202.29009%2020.9366%202.48804Z%22%0A%20%20%20%20%20%20%20%20fill%3D%22%23FFFFFF%22%2F%3E%0A%3C%2Fsvg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    border-color: $interaction;

    &:disabled {
      background-color: $neutral-90;
      border-color: $neutral-90;

      & + label {
        color: $neutral-90;
      }
    }

    &.ng-invalid.ng-touched, &.ng-invalid.ng-dirty {
      background-color: $error;
      border-color: $error;

      & + label {
        color: $error;
      }
    }
  }
}

input[type="checkbox"].switch {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  width: 40px;
  min-width: 40px;
  height: 24px;
  display: inline-flex;
  justify-items: flex-start;
  margin: 0;
  padding: 4px;
  border: none;
  border-radius: 20px;
  background-color: $decoration-transparent-8;
  transition: all 0.15s ease-out;

  &::before {
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $neutral-90;
    transition: all 0.15s ease-out;
  }

  &:disabled {
    opacity: 30%;
  }

  &.ng-invalid.ng-touched, &.ng-invalid.ng-dirty {
    background-color: $error-transparent-8;

    &::before {
      background-color: $error-transparent-50;
    }

    & + label {
      color: $error;
    }
  }

  &:checked {
    background-color: $interaction-transparent-8;

    &::before {
      background-color: $interaction;
      transform: translateX(16px);
    }

    &.ng-invalid.ng-touched, &.ng-invalid.ng-dirty {
      background-color: $error-transparent-8;

      &::before {
        background-color: $error;
      }

      & + label {
        color: $error;
      }
    }
  }
}

.menu-entry {
  color: $neutral-30;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  gap: 9px;
  height: 40px;


  &:hover {
    background-color: $surface-70;
  }

  &.selected {
    background-color: $interaction-transparent-8;
  }

  svg path {
    fill: currentColor;
  }
}


.toast-container {
  position: fixed;
  top: 0;
  right: 0;
  margin-left: 10px;
  z-index: 9999;
  display: flex;
  flex-direction: column-reverse;
  padding: 10px;
  width: 100%;

  @media (min-width: $breakpoint-md) {
    width: 30vw;
  }
}

.card {
  width: 100%;
  background-color: $surface-90;
  border-radius: 8px;
  border: solid 1px $decoration-transparent-8;
  overflow: hidden;

  .card-header {
    background-color: $decoration-transparent-4;
    padding: 16px 24px;

    & + .card-body {
      border-top: solid 1px $decoration-transparent-8;
    }
  }

  .card-body {
    padding: 16px 24px;

    & + .card-body, & + .card-footer {
      border-top: solid 1px $decoration-transparent-8;
    }
  }

  .card-footer {
    padding: 8px 24px;
  }

  &.card-small {
    .card-header {
      padding: 16px !important;
    }

    .card-body {
      padding: 16px !important;
    }
  }

  @media (min-width: $breakpoint-md) {
    &.card-horizontal {
      display: flex;
      flex-direction: row;

      .card-header {
        border-right: solid 1px $decoration-transparent-8;
      }

      .card-body {
        flex: 1;
        border-top: none;
        border-right: solid 1px $decoration-transparent-8;
        padding: 24px;
      }

      .card-header:last-child, .card-body:last-child {
        border-right: none;
      }
    }
  }

  @media (min-width: $breakpoint-md) {
    .card-header {
      padding: 24px 48px;
    }

    .card-body {
      padding: 24px 48px;
    }

    .card-footer {
      padding: 8px 48px;
    }
  }
}

// Display a card only on non-mobile screens
.card-responsive {
  text-align: center;
  display: flex;
  flex-direction: column;

  &.card-responsive-small {
    width: 100%;
    max-width: 580px;
    margin: 0 auto;
  }

  &.card-horizontal {
    gap: 32px;
  }

  .card-header {
    padding: 8px 16px 32px;

    .headline-x-small {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      i.fa-regular, i.fa-solid {
        font-size: 32px;
      }
    }
  }

  @media (min-width: $breakpoint-md) {
    @apply card;
    text-align: left;
    gap: 0 !important;

    &.card-horizontal {
      flex-direction: row;
      gap: 0;
    }

    .card-header {
      .headline-x-small {
        flex-direction: row;
        align-items: center;
        gap: 16px;

        i.fa-regular {
          font-size: inherit;
        }
      }
    }
  }
}

.well {
  padding: 16px;
  border-radius: 8px;
  background-color: $decoration-transparent-4;

  &.well-inverted {
    background-color: $surface-90;
  }
}

// Display a well only on mobile screens
.well-responsive {
  @apply well;

  &.well-inverted {
    background-color: $surface-90;
  }

  @media (min-width: $breakpoint-md) {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }
}

.panel {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 16px;
  color: $interaction-dark;
  border-radius: 8px;
  border: dashed 1px $neutral-90;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;


  &.panel-small {
    flex-direction: row;
    gap: 4px;
    padding: 8px;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  &:hover {
    color: $interaction;
    border: dashed 1px $interaction;
    background-color: $interaction-transparent-4;

    &.disabled {
      color: $neutral-70;
      border: dashed 1px $neutral-70;
      background-color: transparent;
    }
  }

  &.disabled {
    color: $neutral-70;
    border: dashed 1px $neutral-70;
  }

  &.invalid {
    color: $error;
  }
}


.separator {
  display: flex;
  align-items: center;
  gap: 24px;
  color: $neutral-70;

  &::before,
  &::after {
    content: "";
    flex-grow: 1;
    background-color: $neutral-90;
    height: 1px;
  }
}

.ar-input-group {

  &:not(.license-plate-input-second) {
    @apply flex gap-1 flex-col;
  }

  &.ar-input-group-auto {
    @apply inline-flex gap-2 flex-col;
  }

  // Error and help-text text styles
  p {
    @apply text-sm text-gray-500;
    &.warning {
      color: $warning-dark;
      @apply block;
    }

    &.error {
      color: $error;
      @apply block;
    }
  }

  // When a warning or an error text is visible, hide the normal texts next to warning and error messages
  // Don't hide p elements not next to warnings and errors
  &:has(p.warning) {
    p.warning + p:not(.warning) {
      @apply hidden;
    }

    p:not(.warning):has(+ p.warning) {
      @apply hidden;
    }
  }

  &:has(p.error) {
    p.error + p:not(.error) {
      @apply hidden;
    }

    p:not(.error):has(+ p.error) {
      @apply hidden;
    }
  }

}

.flex-responsive {
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-md) {
    flex-direction: row;
  }
}

.table-primary {
  width: 100%;
  border-collapse: collapse;

  &.table-expandable {
    tr {
      th:first-child, td:first-child {
        box-sizing: content-box;
        padding: 8px;
        width: 22px;
        min-width: 22px;
        max-width: 22px;
      }
    }
  }

  tr {
    th {
      color: $neutral-30;
      text-align: left;
      padding: 8px 16px;
      border-bottom: solid 1px $decoration-transparent-20;
    }

    td {
      color: $neutral-30;
      text-align: left;
      padding: 8px 16px;
      border-bottom: solid 1px $decoration-transparent-8;
    }

    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}

.info {
  @media (min-width: $breakpoint-xl) {
    display: block;
    padding: 16px;
    border-radius: 8px;
    background-color: $interaction-transparent-4;
  }
}
