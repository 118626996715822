@import "node_modules/@adyen/adyen-web/dist/es/adyen.css";
@import "variables";
@import "fonts";
@import "buttons";

.adyen-checkout-payment-methods-list-label {
  color: $surface-10;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid $surface-10;
    margin: 0 10px;
  }
}

.adyen-checkout__button {
  @apply button-primary;
}

.adyen-checkout__threeds2__challenge {
  @apply ml-auto mr-auto;
}
