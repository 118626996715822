@import "src/app/styles/variables";
@import "src/app/styles/fonts";
@import "src/app/styles/styled-components";
@import "src/app/styles/adyen";
@import "@awesome.me/kit-5d77c818f8/icons/css/all.css";

@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #F9F9F7;
  font-family: "Noto Sans", sans-serif;
}

a {
  text-decoration: underline;
  color: $interaction-dark;
}

a:hover {
  color: $interaction;
}

.border-1 {
  border-width: 1px;
}

@layer utilities {
  .headline-display {
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 38px;
    color: $decoration-dark;

    @media (min-width: $breakpoint-md) {
      font-size: 53px;
      line-height: 76px;
    }
  }

  .headline-large {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 34px;
    color: $decoration-dark;

    @media (min-width: $breakpoint-md) {
      font-weight: 300;
      font-size: 43px;
      line-height: 62px;
    }
  }

  .headline-medium {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 34px;
    color: $decoration-dark;

    @media (min-width: $breakpoint-md) {
      font-weight: 300;
      font-size: 34px;
      line-height: 49px;
    }
  }

  .headline-small {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: $decoration-dark;

    @media (min-width: $breakpoint-md) {
      font-weight: 500;
      font-size: 27px;
      line-height: 39px;
    }
  }

  .headline-x-small {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: $decoration-dark;

    @media (min-width: $breakpoint-md) {
      font-weight: 500;
      font-size: 22px;
      line-height: 32px;
    }
  }

  .body-large {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $neutral-30;

    @media (min-width: $breakpoint-md) {
      font-size: 17px;
      line-height: 25px;
    }
  }

  .body-medium {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $neutral-30;

    @media (min-width: $breakpoint-md) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .body-small {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $neutral-30;

    @media (min-width: $breakpoint-md) {
      font-size: 11px;
      line-height: 16px;
    }
  }

  .body-x-small {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    color: $neutral-30;

    @media (min-width: $breakpoint-md) {
      font-size: 9px;
      line-height: 13px;
    }
  }

  .cta-large {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: $interaction-dark;
  }

  .cta-medium {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $interaction-dark;
  }

  .cta-small {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: $interaction-dark;
  }
}

.text-decoration-none {
  text-decoration: none;
}

.text-underlined {
  text-decoration: underline;
}

.text-decoration-dark {
  color: $decoration-dark;
}

.text-decoration {
  color: $decoration;
}

.text-interaction-dark {
  color: $interaction-dark;
}

.text-interaction {
  color: $interaction;
}

.text-neutral-10 {
  color: $neutral-10;
}

.text-neutral-30 {
  color: $neutral-30;
}

.text-neutral-50 {
  color: $neutral-50;
}

.text-neutral-70 {
  color: $neutral-70;
}

.text-error {
  color: $error;
}

.text-warning {
  color: $warning;
}

.text-success {
  color: $success;
}

.text-success-dark {
  color: $success-dark;
}

.gm-style iframe + div {
  border-color: transparent !important;
}

.map-button {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  border-radius: 20px;
  background-color: $surface-90;
  color: $decoration;
  font-size: 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3) !important;

  &:hover {
    color: $decoration-light;
  }
}

.leaflet-tile-pane {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
