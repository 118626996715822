.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  border-radius: 80px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.button-primary {
  @apply button;
  color: $surface-90;
  background-color: $interaction-dark;
  border: 1px solid $interaction-dark;

  &:hover {
    border: 1px solid $interaction;
    background-color: $interaction;
  }

  &:disabled {
    border: 1px solid $neutral-90;
    background-color: $neutral-90
  }
}

.button-primary-outlined {
  @apply button;
  color: $interaction-dark;
  border: 1px solid $interaction-dark;

  &:hover {
    color: $interaction;
    border: 1px solid $interaction;
  }

  &:disabled {
    color: $neutral-90;
    border: 1px solid $neutral-90;
  }

  svg path {
    fill: currentColor;
  }
}

.button-primary-text {
  color: $interaction-dark;
  @apply button;

  &:hover {
    color: $interaction;
  }

  &:disabled {
    color: $neutral-90;

  }

  svg path {
    fill: currentColor;
  }
}

.button-warning {
  @apply button;
  color: $surface-90;
  background-color: $warning-dark;
  border: 1px solid $warning-dark;

  &:hover {
    border: 1px solid $warning;
    background-color: $warning;
  }

  &:disabled {
    border: 1px solid $neutral-90;
    background-color: $neutral-90
  }
}

.button-warning-outlined {
  @apply button;
  color: $warning-dark;
  border: 1px solid $warning-dark;

  &:hover {
    color: $warning;
    border: 1px solid $warning;
  }

  &:disabled {
    color: $neutral-90;
    border: 1px solid $neutral-90;
  }

  svg path {
    fill: currentColor;
  }
}

.button-warning-text {
  color: $warning-dark;
  @apply button;

  &:hover {
    color: $warning;
  }

  &:disabled {
    color: $neutral-90;

  }

  svg path {
    fill: currentColor;
  }
}


.button-danger {
  @apply button;
  color: $surface-90;
  background-color: $error;
  border: 1px solid $error;

  &:hover {
    border: 1px solid $error-transparent-50;
    background-color: $error-transparent-50;
  }

  &:disabled {
    border: 1px solid $neutral-90;
    background-color: $neutral-90
  }
}

.button-danger-outlined {
  @apply button;
  color: $error;
  border: 1px solid $error;

  &:hover {
    color: $error-transparent-50;
    border: 1px solid $error-transparent-50;
  }

  &:disabled {
    color: $neutral-90;
    border: 1px solid $neutral-90;
  }

  svg path {
    fill: currentColor;
  }
}

.button-danger-text {
  color: $error;
  @apply button;

  &:hover {
    color: $error-transparent-50;
  }

  &:disabled {
    color: $neutral-90;

  }

  svg path {
    fill: currentColor;
  }
}


.button-success {
  @apply button;
  color: $surface-90;
  background-color: $success-dark;
  border: 1px solid $success-dark;

  &:hover {
    border: 1px solid $success;
    background-color: $success;
  }

  &:disabled {
    border: 1px solid $neutral-90;
    background-color: $neutral-90
  }
}

.button-success-outlined {
  @apply button;
  color: $success-dark;
  border: 1px solid $success-dark;

  &:hover {
    color: $success;
    border: 1px solid $success;
  }

  &:disabled {
    color: $neutral-90;
    border: 1px solid $neutral-90;
  }

  svg path {
    fill: currentColor;
  }
}

.button-success-text {
  color: $success-dark;
  @apply button;

  &:hover {
    color: $success;
  }

  &:disabled {
    color: $neutral-90;

  }

  svg path {
    fill: currentColor;
  }
}


.button-small {
  padding: 8px 16px;
  font-size: 14px;
  line-height: 16px;
  height: 32px;
}

.button-select {
  display: flex;
  align-items: center;
  gap: 16px;

  & > span {
    cursor: pointer;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px 8px 8px;
    border: solid 2px $interaction-transparent-8;
    background-color: $surface-90;
    border-radius: 8px;
    color: $interaction-dark;
    font-weight: 500;
    font-size: 14px;
  }

  input[type="radio"] {
    display: none;
  }

  @media (min-width: $breakpoint-md) {
    & > span {
      flex-direction: row;
      gap: 10px;
      padding: 16px;
    }

    &.button-select-radio > input[type="radio"] {
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
      width: 24px;
      height: 24px;
      display: inline-flex;
      align-items: center;
      justify-items: center;
      margin: 0;
      background-color: $surface-90;
      border-style: solid;
      border-width: 1px;
      border-color: $neutral-50;
      box-shadow: inset 0 0 0 3px $surface-90;
      border-radius: 24px;
      transition: all 0.15s ease-out;

      &:checked {
        background-color: $interaction;
        border-color: $interaction;
      }

      &:disabled {
        border-color: $neutral-90;
      }
    }
  }

  &.button-select-small > span {
    padding: 12px;
  }

  input[type="radio"].ng-invalid.ng-touched, &.ng-invalid.ng-dirty + span {
    border: solid 2px $error-transparent-8;
    color: $error;
  }

  input[type="radio"]:checked {
    & + span {
      background-color: $interaction-transparent-4;
      border: solid 2px $interaction;
    }

    &.ng-invalid.ng-touched, &.ng-invalid.ng-dirty + span {
      background-color: $error-transparent-8;
      border: solid 2px $error;
    }
  }

  input[type="radio"]:disabled + span {
    border: solid 2px $interaction-transparent-4;
    color: $interaction-light;
  }
}
