$breakpoint-2xl: 1536px;
$breakpoint-xl: 1280px;
$breakpoint-lg: 1024px;
$breakpoint-md: 768px;
$breakpoint-sm: 640px;

$interaction-dark: #094FAD;
$interaction: #398BE3;
$interaction-light: #9EC6F1;
$interaction-transparent-4: #094FAD0A;
$interaction-transparent-8: #094FAD14;
$interaction-transparent-20: #094FAD33;

$decoration-dark: #021B63;
$decoration: #424393;
$decoration-light: #7570C7;
$decoration-transparent-4: #4243930A;
$decoration-transparent-8: #42439314;
$decoration-transparent-12: #4243931F;
$decoration-transparent-20: #42439333;

$accent-dark: #912E7B;
$accent: #B956A3;
$accent-light: #DE82CA;

$accent-alternative-transparent-8: #BEA23614;
$accent-alternative-transparent-40: #BEA23666;

$neutral-10: #000000;
$neutral-30: #343642;
$neutral-50: #60626F;
$neutral-70: #8F919F;
$neutral-90: #C2C4D2;


$surface-10: #C5C3C0;
$surface-30: #DCDAD7;
$surface-50: #EEECEA;
$surface-70: #F9F9F7;
$surface-90: #FFFFFF;

$error: #AE1313;
$error-transparent-50: #AE131380;
$error-transparent-8: #AE131314;
$error-transparent-20: #AE131333;

$success-dark: #7F8F0A;
$success: #B1C12E;
$success-transparent-8: #B1C12E14;
$success-transparent-20: #B1C12E33;

$warning-dark: #CA8217;
$warning: #E8A035;
$warning-transparent-8: #E8A03514;
$warning-transparent-20: #E8A03533;
